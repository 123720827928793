import { Box, Grid, InputAdornment } from '@mui/material';
import EntityManager from 'app/modules/components/EntityManager';
import { UseEntityManagerReturnType } from 'app/modules/components/EntityManager/useEntityManager';

import ProductDetailFormModal from './components/ProductDetailFormModal';
import useFindUpcState from './hook/useFindUpcState';
import useUpcSearchSuggestion from './hook/useUpcSearchSuggestion';
import { getUPCResultTableColumns } from './columns';
import { CustomIcons } from '@procurenetworks/procure-component-library';

interface Props {
  onSaveProductData: (values: any) => void;
  findUpcSearch?: UseEntityManagerReturnType['search'];
  findtable?: UseEntityManagerReturnType['table'];
}

const FindUPC = (props: Props) => {
  const { onSaveProductData } = props;
  const { state, setState, search, table, fetchMore, onProductCodeClick } = useFindUpcState();
  const { suggestionData, getProductsForSuggestion } = useUpcSearchSuggestion();
  return (
    <>
      <Grid container>
        <Grid item xs md={12}>
        <Box className='border border-grey-300 rounded-[12px]' sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}}>
          <EntityManager.Table
            ignoreRelayPagination
            pagination
            actions={table.actions}
            columns={getUPCResultTableColumns()}
            data={state.products}
            extraProps={{
              onProductCodeClick,
            }}
            fetchMore={fetchMore}
            loading={state.loading}
            persistKey="find_upc__table"
            setState={table.setState}
            state={table.state}
            total={state.totalCount}
            filterNodes={
              <EntityManager.Search
                {...search}
                isDropdown
                suggestionData={suggestionData || []}
                onChangeSearch={getProductsForSuggestion}
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
            }
            paginationWrapperClass='p-[24px] flex-wrap md:gap-[24px]'
          />
          </Box>
        </Grid>
        
      </Grid>
      {!!state.productDetailModal.selectedProduct ? (
        <ProductDetailFormModal setState={setState} state={state} onSubmit={onSaveProductData} />
      ) : undefined}
    </>
  );
};

export default FindUPC;
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Autocomplete, createFilterOptions, FilterOptionsState, TextField } from '@mui/material';
import Common from 'app/i18n/Common';
import Orders from 'app/i18n/Orders';
import { removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';
import useDebounce from 'app/hooks/useDebounce';

interface Props {
  value: string;
  placeholderText?: string;
  autoFocus?: boolean;
  onChange: (value: string) => void;
  onChangeSearch?: (value: string) => void;
  suggestionData?: suggestionData[];
  isDropdown?: boolean;
  removedSearchQoutedValue?: boolean;
  isWishlist?: boolean;
  startAdornment?: ReactNode;
  placeholder?: string;
  searchFieldWrapper?: string;
  paginated?: boolean;
  onScroll?: any;
}

function parseSearchInput(search: string) {
  return (search || '').replace(/^['"]+|['"]+$/g, '');
}

const filter = createFilterOptions();

export type suggestionData = {
  label: string;
  id: string;
  value?: string;
  freeSolo?: boolean;
};

const NO_RESULT_FOUND = 'No Result Found';

function EntityManagerSearch(props: Props) {
  const {
    onChange,
    suggestionData,
    isDropdown,
    onChangeSearch,
    value,
    startAdornment,
    placeholder,
    searchFieldWrapper = 'w-full md:w-[374px] lg:w-[400px]',
    paginated, //make this props if want to dynamic pagination
    onScroll, //function use for scroll the list
  } = props;
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [isUrlSearchPresent, setIsUrlSearchPresent] = useState(false);

  const match = require('autosuggest-highlight/match');
  const parse = require('autosuggest-highlight/parse');

  useEffect(() => {
    const storedSearchValue = new URLSearchParams(window.location.search).get('search');
    if (storedSearchValue !== null && storedSearchValue !== '') {
      setInputSearchValue(storedSearchValue);
      onChangeSearch?.(storedSearchValue);
      onSearchChange(storedSearchValue);
      setIsUrlSearchPresent(true);
    }
  }, []);

  useEffect(() => {
    if (isUrlSearchPresent) {
      const params = new URLSearchParams(window.location.search);
      if (inputSearchValue !== null && inputSearchValue !== '') {

        params.set('search', inputSearchValue);
        const queryString = params.toString();
        window.history.pushState(null, '', `${window.location.pathname}?${queryString}`);

        setInputSearchValue(inputSearchValue);
        onChangeSearch?.(inputSearchValue);
        onSearchChange(inputSearchValue)
      } else {
        params.delete('search');
        const queryString = params.toString();
        const url = queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname;
        window.history.pushState({}, '', url);
      }
      setIsUrlSearchPresent(false);
    }
  }, [isUrlSearchPresent]);

  const onSearchChange = useCallback(
    (value: string): void => {
      onChange(value);
    },
    [onChange],
  );

  useEffect(() => {
    if (value !== inputSearchValue) {
      setInputSearchValue(value);
    }
  }, [value]);

  const debouncedSearchValue = useDebounce(inputSearchValue, 300);
  useEffect(() => {
    if (isDropdown && debouncedSearchValue !== '') {
      onChangeSearch?.(debouncedSearchValue);
    }
  }, [debouncedSearchValue, isDropdown, onChangeSearch]);

  const handleInputChange = useCallback(
    (event: any, value: string, reason: string) => {
      if (value !== NO_RESULT_FOUND) {
        setInputSearchValue(value);
        if (isDropdown) {
          if (reason === 'reset' || reason === 'clear' || !value) {
            onSearchChange(value);
            setIsUrlSearchPresent(true);
          }
        } else {
          onSearchChange(value);
          setIsUrlSearchPresent(true);
        }
      }
    },
    [isDropdown]
  );

  return (
    <Autocomplete
      disablePortal
      freeSolo
      filterOptions={(options, params) => {
        const filtered = filter(options, params as FilterOptionsState<unknown>);

        if (params.inputValue !== '' && !options.length && isDropdown) {
          filtered.push({
            freeSolo: true,
            inputValue: params.inputValue,
            label: NO_RESULT_FOUND,
          });
        }

        const filteredOptions = filtered.length ? (filtered as suggestionData[]) : options;
        return filteredOptions;
      }}
      id="combo-box-demo"
      inputValue={parseSearchInput(inputSearchValue)}
      noOptionsText={Orders.FormValidationMessages.NoRecordFound}
      options={suggestionData || []}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete="off"
          label={Common.Actions.Search}
          placeholder={placeholder}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '50px',
            },
            '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
              padding: '0px !important',
            },
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const noResult = option.label === NO_RESULT_FOUND;
        const matches = match(option?.value || option.label, inputValue);
        const parts = parse(option?.value || option.label, matches);

        return (
          <li
            {...props}
            key={option.id}
            style={{
              cursor: noResult ? 'default' : 'pointer',
              background: noResult ? 'transparent' : '',
              color: noResult ? '#475467' : '',
            }}>
            <div>
              {parts.map((part: any, index: any) => (
                <span
                  key={index}
                  className={`${part.highlight && 'font-bold'} ${part.highlight ? 'text-grey-900' : 'text-grey-800'
                    }`}>
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
      className={searchFieldWrapper}
      onChange={(event: any, option) => {
        if (typeof option === 'object' && option?.freeSolo) {
        } else if (isDropdown && option) {
          const label = (option as suggestionData).label;
          const searchValue = label
            ? label
            : event.code === 'Enter' || event.code === 'NumpadEnter'
              ? typeof option === 'string'
                ? removeMultipleSpaces(option)
                : option
              : option;
          const value = typeof searchValue === 'string' ? searchValue : searchValue.label;
          onSearchChange(value);
          onChangeSearch?.(value);
          setIsUrlSearchPresent(true);
        }
      }}
      onInputChange={handleInputChange}
      onKeyDown={(event) => {
        if ((event.code === 'Enter' || event.code === 'NumpadEnter') && isDropdown) {
          onSearchChange((event.target as any).value.trim());
          setIsUrlSearchPresent(true);
        }
      }}
      ListboxProps={
        paginated
          ? {
            onScroll: onScroll,
            role: 'list-box',
          }
          : {}
      }
    />
  );
}

export default EntityManagerSearch;

import { ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import Orders from 'app/i18n/Orders';

import { SnackbarService } from '../../../../components/Snackbar';
import { WarningService } from '../../../../components/WarningAlert';
import Common from '../../../../i18n/Common';
import {
  OrderRequestItemSchema,
  OrderRequestItemStatusEnum,
  OrderRequestTypeEnum,
  ReturnOrderRequestItemsInput,
} from '../../../../types/schema';
import ItemsInStockSearchDialog from '../../../assetsInventory/components/ItemInStockSearchDialog';
import { ItemInStockDialogForEnum } from '../../../assetsInventory/components/ItemInStockSearchDialog/type';
import { isValidUpdateOrderRequestItems, returnOrderRequestInput } from '../../dto/dto';
import useOrderEditPermissionCheck from '../../hook/useOrderEditPermissionCheck';
import { isOrderItemReturnable } from '../../hook/useOrderReturnPermissionCheck';
import { OrderDetailContext } from '../../provider/OrderDetailsContext';
import { UseOrderDetailsReturnType } from '../../views/OrderDetail/hook/useOrderDetails';
import {
  findOrderItemById,
  getDuplicateOrderItem,
  parseItemInStockToExistingOrderItem,
  updateOrderItem,
} from '../../views/OrderDetail/hook/useOrderDetails/utils/utils';
import useOrderRequestItems from '../../views/OrderDetail/hook/useOrderRequestItems';
import { ORDER_VIEW } from '../../views/OrderDetail/type';
import { isActionFieldEnabled } from '../../views/OrderDetail/utils';
import { SupportedOrderItemTypes } from '../../views/OrderRequest/components/OrderItemRequest/type';
import { ItemInStock } from '../../views/OrderRequest/type';
import OrderDetailActions from '../OrderDetailActions';
import OrderDetailTable from '../OrderDetailTable';
import { getOrderDetailItemsColumns } from './columns';
import { OrderItemsExtraPropType } from './type';
import { get, orderBy } from 'lodash';
import { Box } from '@mui/material';

interface Props {
  state: UseOrderDetailsReturnType['state'];
  actions: UseOrderDetailsReturnType['actions'];
  onUpdateOrder: () => void;
  onReturnOrder: (input: ReturnOrderRequestItemsInput) => void;
  disabled?: boolean;
  filterNode?: ReactElement
}
const OrderItems = (props: Props) => {
  const { state, disabled, actions, onUpdateOrder, onReturnOrder, filterNode } = props;
  const [sortModel, setSortModel] = useState([]);
  // Note: Currently only status column is sortable so we can read it at 0 position.
  // In case of multiple sortable column, we have to find "status" column first
  const sortColumn = sortModel?.[0] || '';

  const {
    orderRequestItems,
    filteredOrderRequestItems,
    orderDetail,
    orderView,
    itemInStockDialogState,
    partnerTenantId,
  } = state;
  const { itemInStockDialogActions } = actions;

  const { isParentTenantOrderView, isChildTenantOrderView } = useContext(OrderDetailContext);

  const { getEditableOrderItems } = useOrderEditPermissionCheck({
    orderDetail,
  });

  const {
    assetRows,
    inventoryRows,
    specialOrderRows,
    canViewAsset,
    canViewInventory,
    canViewNoSku,
    assetTable,
    specialTable,
    inventoryTable,
  } = useOrderRequestItems({
    filtersOrderRequestItems: filteredOrderRequestItems,
    orderRequestItems: orderRequestItems,
    orderView: state.orderView,
  });

  const selectedRowIds = useMemo(() => {
    return [
      ...assetTable.state.selectedRowIds,
      ...inventoryTable.state.selectedRowIds,
      ...specialTable.state.selectedRowIds,
    ];
  }, [
    assetTable.state.selectedRowIds,
    inventoryTable.state.selectedRowIds,
    specialTable.state.selectedRowIds,
  ]);

  const handleReturnItems = useCallback(() => {
    const { isValidReturnOrderRequestItems, input } = returnOrderRequestInput(
      orderDetail,
      orderRequestItems,
      selectedRowIds,
    );

    if (!isValidReturnOrderRequestItems) {
      SnackbarService.showError({
        message: Orders.FormValidationMessages.InvalidQuantityInReturnOrder,
      });
      return;
    }

    onReturnOrder(input);
  }, [selectedRowIds, onReturnOrder, orderRequestItems, orderDetail]);

  const handleUpdateItems = useCallback(() => {
    const { isValidReturnOrderRequestItems, problems } =
      isValidUpdateOrderRequestItems(orderRequestItems);

    if (!isValidReturnOrderRequestItems) {
      problems.forEach((problem) => {
        SnackbarService.showError({
          message: problem,
        });
      });
      return;
    }

    onUpdateOrder();
  }, [onUpdateOrder, orderRequestItems]);

  const headers = useMemo(() => {
    return getOrderDetailItemsColumns({
      isProjectFieldEnabled:
        state.isProjectFieldEnabled && (orderDetail.type === OrderRequestTypeEnum.Internal || orderDetail.type === OrderRequestTypeEnum.External && !isParentTenantOrderView),
      isActionFieldEnabled: isActionFieldEnabled(orderDetail.orderRequestItems, orderView),
    });
  }, [state.isProjectFieldEnabled, orderView, orderDetail.type, orderDetail.orderRequestItems]);

  const editableOrderRequestItems = useMemo(
    () => getEditableOrderItems(orderRequestItems),
    [getEditableOrderItems, orderRequestItems],
  );

  const getOrderRequestItemsByView = useCallback(() => {
    switch (orderView) {
      case ORDER_VIEW.EDIT_ORDER_VIEW:
        return getEditableOrderItems(orderRequestItems);
      case ORDER_VIEW.RETURN_ORDER_VIEW:
        return orderRequestItems;
      default:
        return orderRequestItems;
    }
  }, [orderView, orderRequestItems, getEditableOrderItems]);

  /**
   * It checks for duplicate Order Request items
   * 1.) We update state of order Item
   * 2.) We check for duplicate Order Request items
   * 3.) If duplicate Order Request Item found, we show alert
   * 4.) If user allow to merge duplicate order Items
   *    a) We will cancel Update request Order Item
   *    b) We will update a quantity to Duplicate Order Item.
   * 5.) If User cancel a duplication Merge, We will reset state of order Item
   */
  const handleUpdateOrderItem = useCallback(
    (id: string, nextState: Partial<OrderRequestItemSchema>) => {
      const orderRequestItemsByView = getOrderRequestItemsByView();
      const currentOrderItem = findOrderItemById(orderRequestItemsByView, id);

      if (!currentOrderItem) {
        return;
      }

      // 1.) We update state of order Item
      actions.onUpdateOrderItem(id, nextState);

      // We Only need to check duplication in edit order view.
      if (orderView === ORDER_VIEW.EDIT_ORDER_VIEW) {
        // 2.) We check for duplicate Order Request Items
        const updatedOrderRequestItems = updateOrderItem(orderRequestItemsByView, id, nextState);
        const { duplicateOrderItem, duplicateUpdatedState } = getDuplicateOrderItem(
          updatedOrderRequestItems,
          id,
          {
            isParentTenantOrderView,
            isChildTenantOrderView,
          },
        );

        if (orderDetail.type === OrderRequestTypeEnum.External && isParentTenantOrderView) {
          return;
        }

        if (!duplicateOrderItem) {
          return;
        }

        // 3.) If duplicate Order Request Item found, we show alert
        WarningService.showWarning({
          message: Orders.FormValidationMessages.DuplicateOrderItem(
            duplicateOrderItem.item?.sku || '',
            duplicateUpdatedState.quantity || 0,
          ),
          onConfirm: () => {
            // 4.) If user allow to merge duplicate order Items
            actions.onUpdateOrderItems([
              {
                id: duplicateOrderItem.id,
                ...duplicateUpdatedState,
                quantity: Number(duplicateOrderItem.quantity || 0) + Number(currentOrderItem.quantity || 0),
              },
              {
                id: id,
                status: OrderRequestItemStatusEnum.Cancelled,
              },
            ]);
            if (!currentOrderItem?.item?.sku) {
              actions.onRemoveOrderItem(id);
            }
          },
          onCancel: () => {
            // 5.) If User cancel a duplication Merge, We will reset state of order Item
            actions.onUpdateOrderItem(id, currentOrderItem);
          },
        });
      }
    },
    [
      actions.onUpdateOrderItem,
      actions.onUpdateOrderItems,
      getOrderRequestItemsByView,
      orderView,
      orderDetail.type,
      isParentTenantOrderView,
      isChildTenantOrderView,
    ],
  );

  const updatedEditableOrderItemInStock = useCallback(
    (itemInStock: ItemInStock, orderRequestItemId?: string) => {
      if (orderRequestItemId) {
        const orderRequestItems = getOrderRequestItemsByView();
        const orderRequestItem = findOrderItemById(orderRequestItems, orderRequestItemId);

        if (
          !orderRequestItem ||
          orderRequestItem.itemId === itemInStock.id ||
          (isChildTenantOrderView && orderRequestItem.itemIdInPartnerTenant === itemInStock.id)
        ) {
          itemInStockDialogActions.onHideItemInStockSearchDialog();
          return;
        }

        const updatedOrderRequestItem = parseItemInStockToExistingOrderItem(
          orderRequestItem,
          itemInStock,
        );

        handleUpdateOrderItem(orderRequestItemId, updatedOrderRequestItem);

        if (updatedOrderRequestItem.item?.id) {
          updatedOrderRequestItem.id = updatedOrderRequestItem.item.id
        }

        itemInStockDialogActions.onHideItemInStockSearchDialog();
      }
    },
    [
      itemInStockDialogActions.onHideItemInStockSearchDialog,
      handleUpdateOrderItem,
      getOrderRequestItemsByView,
      isChildTenantOrderView,
      isParentTenantOrderView,
      actions.onUpdateOrderItem,
      actions.onRemoveOrderItem,
    ],
  );

  const onUpdateItemInStock = useCallback(
    (
      itemInStock: ItemInStock,
      meta?: {
        orderRequestItem?: string;
        for?: ItemInStockDialogForEnum;
        onAdd?: (itemInStock: ItemInStock) => void;
      },
    ) => {
      const orderRequestItemId = meta?.orderRequestItem;
      const updateItemInStockFor = meta?.for;
      const onAddHandler = meta?.onAdd;

      const url = new URL(window.location.href);
      url.searchParams.delete('search');
      window.history.pushState({}, '', url);

      switch (updateItemInStockFor) {
        case ItemInStockDialogForEnum.AssetInventoryUpdateItemInStock:
          updatedEditableOrderItemInStock(itemInStock, orderRequestItemId);
          break;
        case ItemInStockDialogForEnum.NoSkuUpdateItemInStock:
          onAddHandler?.(itemInStock);
          itemInStockDialogActions.onHideItemInStockSearchDialog();
      }
    },
    [updatedEditableOrderItemInStock, itemInStockDialogActions.onHideItemInStockSearchDialog],
  );

  const tableExtraProps = useMemo(() => {
    return {
      actions: {
        onUpdateOrderItem: handleUpdateOrderItem,
        imagePreviewActions: actions.imagePreviewActions,
        itemInStockDialogActions: actions.itemInStockDialogActions,
        onAddRow: actions.onAddRow,
        handleRemoveRow: actions.handleRemoveRow
      },
      state: {
        orderDetail,
        orderView,
        editableOrderRequestItems,
        disabled,
        formState: state.formState,
      },
    } as OrderItemsExtraPropType;
  }, [actions, orderDetail]);

  const handleSortChange = (newSortModel: any) => {
    setSortModel(newSortModel);
  };

  const { sortedAssetRows, sortedInventoryRows, sortedSpecialOrderRows } = useMemo(() => {
    if (!sortColumn) {
      return {
        sortedAssetRows: assetRows,
        sortedInventoryRows: inventoryRows,
        sortedSpecialOrderRows: specialOrderRows,
      };
    }

    const sortOrder = get(sortColumn, 'sort', undefined) as unknown as 'asc' | 'desc' | undefined;
    return {
      sortedAssetRows: orderBy(assetRows, 'status', sortOrder),
      sortedInventoryRows: orderBy(inventoryRows, 'status', sortOrder),
      sortedSpecialOrderRows: orderBy(specialOrderRows, 'status', sortOrder),
    };
  }, [assetRows, inventoryRows, specialOrderRows, sortModel]);

  return (
    <div>
      <Box className='mt-[24px] border border-grey-300 rounded-[12px]' sx={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)' }}>
        {canViewAsset ? (
          <OrderDetailTable
            key="default-order-assets"
            extraProps={tableExtraProps}
            table={assetTable}
            headers={headers}
            rows={sortedAssetRows}
            title={Orders.FormLabels.Assets}
            isRowSelectable={isOrderItemReturnable}
            onSortModelChange={handleSortChange}
            filterNode={filterNode}
          />
        ) : null}

        {canViewInventory ? (
          <OrderDetailTable
            key="default-order-inventory"
            extraProps={tableExtraProps}
            table={inventoryTable}
            headers={headers}
            rows={sortedInventoryRows}
            title={Orders.FormLabels.Inventory}
            isRowSelectable={isOrderItemReturnable}
            onSortModelChange={handleSortChange}
            filterNode={filterNode}
          />
        ) : null}

        {canViewNoSku ? (
          <OrderDetailTable
            key="default-order-non-stock-items"
            extraProps={tableExtraProps}
            table={specialTable}
            headers={headers}
            rows={sortedSpecialOrderRows}
            title={Orders.FormLabels.NonStockItems}
            isRowSelectable={isOrderItemReturnable}
            onSortModelChange={handleSortChange}
            filterNode={filterNode}
          />
        ) : null}

        <OrderDetailActions
          onUpdateOrder={handleUpdateItems}
          onReturnOrder={handleReturnItems}
          actions={actions}
          state={state}
          tableState={{
            selectedRowIds,
          }}
          disabled={disabled}
        />
      </Box>
      <ItemsInStockSearchDialog
        actions={itemInStockDialogActions}
        state={itemInStockDialogState}
        types={SupportedOrderItemTypes}
        partnerTenantId={partnerTenantId}
        onConfirm={onUpdateItemInStock}
        confirmButtonText={itemInStockDialogState?.sku === '' ? Common.Actions.Add : Common.Actions.Update}
        onClose={itemInStockDialogActions.onHideItemInStockSearchDialog}
        pickableThroughOrderRequest={true}
      />
    </div>
  );
};

export default OrderItems;

import { useCallback, useEffect, useState } from 'react';
import { UseEntityManagerReturnType } from 'app/modules/components/EntityManager/useEntityManager';

import useFindUPC from '../context/useFindUPC';
import { isValidProductCode } from '../utils/productCode';
import { Offer, Product, useHooverProductsQuery } from './useHooverProducts';

interface State {
  products: SearchProduct[];
  loading: boolean;
  totalCount: number;
  productDetailModal: {
    open: boolean;
    selectedProduct?: SearchProduct;
  };
}

interface SearchProduct {
  id: string;
  name: string;
  modelName: string;
  manufacturer: string;
  globalTradeItemNumber: string;
  description: string;
  images: string[];
}

const parseProductNodes = (products: Product[]): SearchProduct[] => {
  return products.map((product) => {
    const { id, name, modelName, manufacturer, offers, globalTradeItemNumber } = product;

    const offer: Offer | undefined = offers?.nodes?.length ? offers.nodes[0] : undefined;
    return {
      id,
      name,
      modelName,
      manufacturer: manufacturer?.name || '',
      globalTradeItemNumber: globalTradeItemNumber || '',
      description: offer?.description || '',
      images: offer?.images?.nodes?.map((image) => image.original) || [],
    };
  });
};

const useFindUpcState = () => {
  const [state, _setState] = useState<State>({
    loading: false,
    products: [],
    totalCount: 0,
    productDetailModal: {
      open: false,
      selectedProduct: undefined,
    },
  });

  const { search, table } = useFindUPC() as UseEntityManagerReturnType;

  const { fetchHooverProducts } = useHooverProductsQuery();

  const setState = useCallback((nextState: Partial<State>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const handleProductResponse = useCallback(
    (response: any) => {
      const { products } = response?.data || {}
      const { nodes, totalCount } = products || {};
      setState({
        products: parseProductNodes(nodes || []),
        loading: false,
        totalCount,
      });
    },
    [setState],
  );

  const getHooverProducts = useCallback(async () => {
    setState({
      loading: true,
    });

    const variables = {
      name: '',
      page: table.state.activePage,
      totalPage: table.state.numberOfRowsPerPage,
      model: '',
      code: '',
      brand: '',
    };

    if (isValidProductCode(search.debouncedSearchText || '')) {
      variables.code = search.debouncedSearchText || '';
    } else {
      variables.name = search.debouncedSearchText || '';
    }

    const response = await fetchHooverProducts(variables);
    handleProductResponse(response);
  }, [fetchHooverProducts, search.debouncedSearchText, table.state, handleProductResponse]);

  useEffect(() => {
    getHooverProducts();
  }, [table.state.activePage, table.state.numberOfRowsPerPage, search.debouncedSearchText]);

  const fetchMore = useCallback(() => {
    table.setState({
      activePage: table.state.activePage + 1,
    });
  }, [table.state.activePage, table.setState]);

  const onProductCodeClick = useCallback(
    (productData: SearchProduct) => {
      setState({
        productDetailModal: {
          open: true,
          selectedProduct: productData,
        },
      });
    },
    [setState],
  );

  return {
    state,
    setState,
    search,
    table,
    fetchMore: fetchMore,
    onProductCodeClick,
  };
};

export type UseFindUpcStateReturnType = ReturnType<typeof useFindUpcState>;
export default useFindUpcState;
function isProductCodeValid(arr: any[], type: string = 'u') {
  let odd = 0;
  let even = 0;
  let lastElement = arr[arr.length - 1];

  arr.forEach((item) => {
    if (item % 2 !== 0) {
      odd += type === 'u' ? item : item * 3;
    } else {
      even += type === 'u' ? item * 3 : item;
    }
  });

  let total = odd + even;
  total = total % 10;
  total = total === 0 ? 0 : 10 - total;
  return total === lastElement;
}

export function isValidProductCode(code: string) {
  const productCode = code.replaceAll(' ', '');
  const productCodeLength = productCode.length;

  if ([10, 12, 13, 14].includes(productCodeLength) && !/^\s*$/.test(productCode)) {
    if (productCodeLength === 14) {
      return true;
    }

    const productCodeArray = productCode.split('');

    if (productCodeLength >= 10 && productCodeLength < 13) {
      return isProductCodeValid(productCodeArray);
    } else if (productCodeLength >= 13) {
      return isProductCodeValid(productCodeArray, 'i');
    }
  }
  return false;
}

import {
  Button,
  DataGridHeadCell,
  TableHeaderCell,
  ValueComponentProps,
} from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import QuantityInput from 'app/modules/components/QuantityInput';

const MinimumQuantity = (props: ValueComponentProps) => {
  const { tableProps, row } = props;
  const { editable } = row;
  const { extraProps } = tableProps;

  if (!editable) {
    return <span>{row.minimumQuantity}</span>;
  }

  return (
    <QuantityInput
      key={`minimum-quantity-input${row.id}`}
      maximumLength={9}
      size="small"
      value={row?.editableFields?.minimumQuantity || ''}
      onChange={(value) => {
        if (extraProps?.setEditableSiteState) {
          extraProps.setEditableSiteState(row.id, {
            minimumQuantity: value,
          });
        }
      }}
    />
  );
};
const MaximumQuantity = (props: ValueComponentProps) => {
  const { tableProps, row } = props;
  const { editable } = row;
  const { extraProps } = tableProps;

  if (!editable) {
    return <span>{row.maximumQuantity}</span>;
  }

  return (
    <QuantityInput
      key={`maximum-quantity-input${row.id}`}
      maximumLength={9}
      size="small"
      value={row?.editableFields?.maximumQuantity || ''}
      onChange={(value) => {
        if (extraProps?.setEditableSiteState) {
          extraProps.setEditableSiteState(row.id, {
            maximumQuantity: value,
          });
        }
      }}
    />
  );
};

export const getQuantityBySiteColumns = (disabled?: boolean): TableHeaderCell[] => [
  {
    id: 'site',
    label: 'Site',
    value: 'site',
    valueNode: ({ row }) => (
      <span className=" text-[14px] text-grey-900">{row?.site?.name || ''}</span>
    ),
  },
  {
    id: 'totalQuantityFromLocations',
    label: 'Quantity',
    value: 'totalQuantityFromLocations',
    valueNode: ({ row }) => (
      <span
        className={
          row?.totalQuantityFromLocations < row?.minimumQuantity
            ? 'mr-56 text-[red]'
            : ' mr-56 text-[14px] text-grey-900'
        }>
        {row?.totalQuantityFromLocations}
      </span>
    ),
    labelAlign: 'center',
    valueAlign: 'right',
    width: 200,
  },
  {
    id: 'minimumQuantity',
    label: 'Min',
    value: 'minimumQuantity',
    labelAlign: 'center',
    valueAlign: 'center',
    valueNode: MinimumQuantity,
    width: 150,
  },
  {
    id: 'Site',
    label: 'Max',
    value: 'Max',
    labelAlign: 'center',
    valueAlign: 'center',
    valueNode: MaximumQuantity,
    width: 150,
  },
  {
    id: 'id',
    label: 'Action',
    value: 'id',
    labelAlign: 'center',
    valueAlign: 'center',
    valueNode: ({ row, tableProps }) => {
      const { editable } = row;
      const { extraProps } = tableProps;
      return (
        <Button
          disabled={disabled}
          theme={!editable ? 'info' : null}
          onClick={() => {
            if (extraProps?.toggleEditableSiteState) {
              extraProps.toggleEditableSiteState(row.id);
            }
          }}
          classes="min-w-[92px] h-[44px]">
          {editable ? Common.Actions.Cancel : Common.Actions.Edit}
        </Button>
      );
    },
    width: 150,
  },
];

export const getLocationWithQuantityColumns = (windowWidth: number): DataGridHeadCell[] => [
  {
    id: 'name',
    label: 'Location',
    value: 'name',
    sortable: false,
    width: windowWidth - 880,
  },
  {
    id: 'totalQuantity',
    label: 'Quantity',
    value: 'totalQuantity',
    labelAlign: 'right',
    rowAlign: 'right',
    width: 168,
    labelNode: (props) => {
      return (
        <>
          <span className="font-[14px] mr-[35px] font-semibold text-grey-900">Quantity</span>
        </>
      );
    },
    valueNode: ({ row }) => <span className="mr-[25px] text-grey-900">{row?.totalQuantity}</span>,
  },
];

export const getLocationColumns = (): DataGridHeadCell[] => {
  return [
    {
      id: 'name',
      label: 'Location',
      value: 'name',
      sortable: false,
      width: 200,
    },
    {
      id: 'totalQuantity',
      label: 'Quantity',
      value: 'totalQuantity',
      sortable: false,
      rowAlign: 'right',
      width: 100,
    },
  ] as DataGridHeadCell[];
};

import { useCallback, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { AssetReminderInput } from 'app/modules/assets/components/AssetForm/components/AssetReminderField/components/AssetReminderForm/types';
import useAssetReminderState from 'app/modules/assets/components/AssetForm/components/AssetReminderField/hook/useAssetReminderState';
import AssetFormView from 'app/modules/assets/components/AssetFormView';
import { useDeleteAssetItemReminderMutation } from 'app/modules/assets/graphql/mutations/generated/deleteAssetItemReminder';
import { useDeleteAssetKitItemMutation } from 'app/modules/assets/graphql/mutations/generated/deleteAssetKitItem';
import {
  createAddAssetItemReminderInput,
  createUpdateAssetItemReminderInput,
} from 'app/modules/assets/utils/dto';
import { onOperationComplete } from 'app/modules/assetsInventory/utils/utils';
import QuantityBySite from 'app/modules/inventory/components/QuantityBySite';
import QuickLinks from 'app/modules/inventory/components/QuickLinks';
import TransactionHistory from 'app/modules/inventory/components/TransactionHistory';
import { removeExtraSpacesAndNewlines, removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import AccessControl from '../../../../../../components/AccessControl';
import routes from '../../../../../../consts/routes';
import Assets from '../../../../../../i18n/Assets';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  AssetItemEdge,
  AssetItemReminder,
  ItemLocationSchema,
  ItemTypeEnum,
} from '../../../../../../types/schema';
import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';
import { useDeleteAssetItemsMutation } from '../../../../graphql/mutations/generated/deleteAssetItems';
import useAssetForm from '../../../../hook/useAssetForm';
import { useCreateAssetItemReminderMutation } from '../../../AddAsset/graphql/mutations/generated/addAssetReminder';
import { useUpdateAssetItemMutation } from '../../graphql/mutations/generated/updateAssetItem';
import { useUpdateAssetItemReminderMutation } from '../../graphql/mutations/generated/updateAssetItemReminder';
import { useUpdateAssetKitItemMutation } from '../../graphql/mutations/generated/updateAssetKitItem';
import { prepareFormData, prepareRemindersFormData } from './utils';
import Loader from 'app/components/Loader';
import analytics from 'app/analytics';

type Props = {
  assetItem: AssetItemEdge['node'];
} & RouteComponentProps;

const EditAssetContent = (props: Props) => {
  const { assetItem, history } = props;

  const { workspacePermissions } = useCurrentUser();

  const [{ fetching: updatingItem }, onUpdateAssetItem] = useUpdateAssetItemMutation();
  const [{ fetching: deletingAssetItems }, executeDeleteAssetItems] = useDeleteAssetItemsMutation();
  const [{ fetching: updatingKitItem }, onUpdateAssetKitItem] = useUpdateAssetKitItemMutation();
  const [{ fetching: deletingAssetKitItems }, executeDeleteAssetKitItem] =
    useDeleteAssetKitItemMutation();
  const [{ fetching: creatingAssetItemReminders }, onCreateAssetItemReminder] =
    useCreateAssetItemReminderMutation();
  const [{ fetching: updatingAssetItemReminders }, onUpdateAssetItemReminder] =
    useUpdateAssetItemReminderMutation();
  const [{ fetching: deletingAssetItemReminders }, onDeleteAssetItemReminder] =
    useDeleteAssetItemReminderMutation();

  const formValues = useMemo(() => {
    return prepareFormData(assetItem);
  }, [assetItem]);
  const reminders = useMemo(() => {
    return prepareRemindersFormData(assetItem?.reminders as AssetItemReminder[], assetItem?.id);
  }, [assetItem?.id, assetItem?.reminders]);

  const { formState, createUpdateAssetItemInput, createUpdateAssetKitItemInput, onCancelEditable } =
    useAssetForm({
      defaultValues: formValues,
      defaultEditable: false,
      assetItem: assetItem,
      isEditMode: true,
    });
  const { state: assetReminderState, setState: setAssetReminderState } = useAssetReminderState({
    reminders,
    editable: formState?.editable,
  });

  const { cancelEditable } = formState;

  const stockInformation = useMemo(() => {
    return assetItem?.stockInformation || [];
  }, [assetItem]);

  const onCancel = useCallback(() => {
    onCancelEditable();
  }, [onCancelEditable]);

  const saveReminders = useCallback(
    async (assetItemId: string) => {
      try {
        await Promise.all([
          ...assetReminderState?.reminders?.map((reminder: AssetReminderInput) => {
            if ((reminder as any)?.isNew) {
              analytics?.track('Created', { name: 'Asset Reminder' });
              return onCreateAssetItemReminder({
                input: createAddAssetItemReminderInput(reminder, assetItemId),
              });
            }
            if ((reminder as any)?.isUpdated) {
              analytics?.track('Edited', { name: 'Asset Reminder' });
              return onUpdateAssetItemReminder({
                input: createUpdateAssetItemReminderInput(reminder),
              });
            }
            if ((reminder as any)?.isDeleted) {
              analytics?.track('Deleted', { name: 'Asset Reminder' });
              return onDeleteAssetItemReminder({
                input: { reminderId: (reminder as any).id },
              });
            }
          }),
        ]);
      } catch (error) {
        console.log(error);
      }
    },
    [
      assetReminderState?.reminders,
      onCreateAssetItemReminder,
      onDeleteAssetItemReminder,
      onUpdateAssetItemReminder,
    ],
  );

  const onSubmit = useCallback(
    (values: any) => {
      const itemValues = {
        ...values,
        title: removeMultipleSpaces(values.title),
        description: removeExtraSpacesAndNewlines(values.description),
        formattedDescription: values.description,
      };
      if (itemValues && itemValues.type === ItemTypeEnum.AssetKit) {
        onUpdateAssetKitItem({
          input: createUpdateAssetKitItemInput(itemValues),
        }).then((response) => {
          analytics?.track('Edited', { name: 'Asset Kit' });
          onOperationComplete({
            response,
            message: Assets.SuccessMessages.AssetKitUpdated,
            error: '[Update Asset Kit] Failed',
            operation: 'updateAssetKitItem',
            cb: cancelEditable,
          });
        });
      } else {
        onUpdateAssetItem({
          input: createUpdateAssetItemInput(itemValues),
        }).then((response) => {
          analytics?.track('Edited', { name: 'Asset' });
          onOperationComplete({
            response,
            message: Assets.SuccessMessages.AssetUpdated,
            error: '[Update Asset] Failed',
            operation: 'updateAssetItem',
            cb: cancelEditable,
          });
        });
      }
    },
    [
      assetItem,
      assetReminderState?.reminders,
      cancelEditable,
      createUpdateAssetItemInput,
      createUpdateAssetKitItemInput,
      onUpdateAssetItem,
      onUpdateAssetKitItem,
      saveReminders,
    ],
  );

  const onDelete = useCallback(() => {
    if (assetItem && assetItem.type === ItemTypeEnum.AssetKit) {
      executeDeleteAssetKitItem({
        input: {
          assetKitItemId: assetItem.id,
        },
      }).then((response) => {
        analytics?.track('Deleted', { name: 'Asset Kit' });
        onOperationComplete({
          response,
          message: Assets.SuccessMessages.AssetKitDeleted,
          error: '[Delete Asset Kit] Failed',
          operation: 'deleteAssetKitItem',
          redirect: routes.AssetsSearch(),
          history,
        });
      });
    } else {
      executeDeleteAssetItems({
        input: {
          itemIds: [assetItem.id],
        },
      }).then((response) => {
        analytics?.track('Deleted', { name: 'Asset' });
        onOperationComplete({
          response,
          message: Assets.SuccessMessages.AssetDeleted,
          error: '[Delete Asset] Failed',
          operation: 'deleteAssetItems',
          redirect: routes.AssetsSearch(),
          history,
        });
      });
    }
  }, [assetItem, executeDeleteAssetItems, executeDeleteAssetKitItem, history]);

  const _disabled = useMemo(
    () => updatingItem || deletingAssetItems || updatingKitItem || deletingAssetKitItems,
    [deletingAssetItems, deletingAssetKitItems, updatingItem, updatingKitItem],
  );

  if (updatingItem) return <Loader />;
  return (
    <div>
      <AssetFormView
        disabled={_disabled}
        loading={_disabled || deletingAssetItems}
        state={{ ...formState, state: assetReminderState, setState: setAssetReminderState }}
        onCancel={onCancel}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />

      <QuickLinks itemInStockId={assetItem.id} type={ItemTypeEnum.Asset} />

      <AccessControl
        action={AllowedPermissionActionsEnum.Edit}
        permissions={workspacePermissions}
        subject={AllowedPermissionsSubjectEnum.Asset}>
        <QuantityBySite
          itemId={assetItem.id}
          stockInformation={stockInformation as ItemLocationSchema[]}
        />
      </AccessControl>

      <div className="mt-20">
        <TransactionHistory itemId={assetItem.id} itemType={ItemTypeEnum.Asset} />
      </div>
    </div>
  );
};

export default withRouter(EditAssetContent);
